import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { AutoRow } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'
import useTheme from '../../hooks/useTheme'
import { ButtonPrimary } from 'components/Button'

const InputRow = styled.div<{ disabled?: boolean }>`
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  width: 100%;
  background-color: ${({ theme, disabled }) => (disabled ? darken(0.2, theme.black) : theme.bg2)};
  border-radius: 14px;
  height: 3rem;
  ${({ theme }) => theme.flexRowNoWrap}
`

const InputNFTRow = styled.div<{ disabled?: boolean }>`
  align-items: center;
  padding: 0 20px;
  width: 100%;
  /* background-color: ${({ theme, disabled }) => (disabled ? darken(0.2, theme.black) : theme.bg2)}; */
  border-radius: 10px;
  height: 60px;
  border: 1px solid rgba(0,0,0,0.1);
  ${({ theme }) => theme.flexRowNoWrap}
`

const CustomNumericalInput = styled(NumericalInput)<{ disabled?: boolean }>`
  font-size: 16px;
  background-color: transparent;
  color: ${({ theme, disabled }) => (disabled ? darken(0.6, theme.white) : theme.white)};
`

const CustomNFTNumericalInput = styled(NumericalInput)<{ disabled?: boolean }>`
  font-size: 16px;
  background-color: transparent;
  color: ${({ theme, disabled }) => (disabled ? darken(0.6, theme.black) : theme.black)};
`

const LabelRow = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
  margin-bottom: 4px;
  ${({ theme }) => theme.flexRowNoWrap}
`

const InputPanel = styled.div<{ negativeMarginTop?: string }>`
  position: relative;
  border-radius: 14px;
  z-index: 1;
  ${({ theme }) => theme.flexColumnNoWrap}
  ${({ negativeMarginTop }) => `${negativeMarginTop ? 'margin-top: ' + negativeMarginTop : ''}`}
`

const StyledBalanceMax = styled(ButtonPrimary)`
  font-size: 0.875rem;
  padding: 0 1rem;
  width: max-content;
  height: 28px;
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

const Container = styled.div``

interface NumberInputPanelProps {
  disabled?: boolean
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  hideBalance?: boolean
  hideLabel?: boolean
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  negativeMarginTop?: string
  intOnly?: boolean
}

export default function NumberInputPanel({
  disabled = false,
  value,
  onMax,
  label = 'Input',
  hideBalance = false,
  hideLabel = false,
  showMaxButton,
  id,
  customBalanceText,
  negativeMarginTop,
  onUserInput,
  intOnly
}: NumberInputPanelProps) {
  const { account } = useActiveWeb3React()
  const theme = useTheme()

  return (
    <InputPanel id={id} negativeMarginTop={negativeMarginTop}>
      <Container>
        <LabelRow>
          <AutoRow justify="space-between">
            {!hideLabel && (
              <TYPE.body color={theme.text2} fontSize={14} fontWeight={500}>
                {label}
              </TYPE.body>
            )}
            {account && (
              <TYPE.body
                onClick={onMax}
                color={theme.text2}
                fontWeight={500}
                fontSize={14}
                style={{ display: 'inline', cursor: 'pointer' }}
              >
                {!hideBalance ? (customBalanceText ?? 'Your balance: ') + '' : ''}
              </TYPE.body>
            )}
          </AutoRow>
        </LabelRow>
        <InputRow disabled={disabled}>
          <CustomNumericalInput
            placeholder={intOnly ? '0' : undefined}
            disabled={disabled}
            isInt={intOnly}
            className="token-amount-input"
            value={value}
            onUserInput={val => {
              onUserInput(val)
            }}
          />
          {account && showMaxButton && <StyledBalanceMax onClick={onMax}>Max</StyledBalanceMax>}
        </InputRow>
      </Container>
    </InputPanel>
  )
}

export function NumberNFTInputPanel({
  disabled = false,
  value,
  onMax,
  label = 'Input',
  hideBalance = false,
  hideLabel = false,
  showMaxButton,
  id,
  balance,
  customBalanceText,
  negativeMarginTop,
  onUserInput,
  intOnly,
  error
}: NumberInputPanelProps & { balance?: string; error?: string }) {
  const { account } = useActiveWeb3React()
  const theme = useTheme()

  return (
    <InputPanel id={id} negativeMarginTop={negativeMarginTop}>
      <Container>
        <LabelRow style={{ margin: '2px 0 9px' }}>
          <AutoRow justify="space-between">
            {!hideLabel && (
              <TYPE.body color={theme.text1} fontSize={14} fontWeight={500}>
                {label}
              </TYPE.body>
            )}
            {account && (
              <TYPE.body
                onClick={onMax}
                color={theme.black}
                fontWeight={400}
                fontSize={14}
                style={{ display: 'inline', cursor: 'pointer' }}
              >
                {!hideBalance ? (customBalanceText ?? 'Your balance: ') + balance : ''}
              </TYPE.body>
            )}
          </AutoRow>
        </LabelRow>
        <InputNFTRow disabled={disabled}>
          <CustomNFTNumericalInput
            placeholder={intOnly ? '0' : undefined}
            disabled={disabled}
            isInt={intOnly}
            className="token-amount-input"
            value={value}
            onUserInput={val => {
              onUserInput(val)
            }}
          />
          {account && showMaxButton && <StyledBalanceMax onClick={onMax}>Max</StyledBalanceMax>}
        </InputNFTRow>
        <TYPE.error error={!!error}>{error}</TYPE.error>
      </Container>
    </InputPanel>
  )
}
