import { useWeb3React } from '@web3-react/core'
import { TransactionResponse } from '@ethersproject/providers'
import { useMemo, useRef } from 'react'
import { useGovernanceContract } from './useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import { calculateGasMargin } from 'utils'
import { VoteOption } from 'pages/NFTGovernance/NFTGovernanceDetail'
import { JSBI } from '@uniswap/sdk'

function calcVoteForPercentage(type: VoteOption, voteFor?: string | number, voteAgainst?: string | number): string {
  if (!voteFor || !voteAgainst) return '0'
  const count = JSBI.add(JSBI.BigInt(voteFor), JSBI.BigInt(voteAgainst))
  if (!JSBI.toNumber(count)) return '0'
  let percentage = JSBI.toNumber(
    JSBI.divide(JSBI.multiply(JSBI.BigInt(10000), JSBI.BigInt(type === VoteOption.FOR ? voteFor : voteAgainst)), count)
  ).toString()
  percentage = (parseFloat(percentage) / 100).toFixed(2)
  return percentage
}

export interface GovernanceContent {
  summary: string
  details: string
  agreeFor: string
  againstFor: string
}

export interface Users {
  totalNo: string
  totalStake: string
  totalYes: string
  stakeEndTime: number
}

enum StatusOption {
  Live = 'Live',
  Success = 'Success',
  Failed = 'Failed'
}

export interface GovernanceData {
  id: string
  title: string
  creator: string
  contents: GovernanceContent | undefined
  timeLeft: string
  voteFor: string
  voteAgainst: string
  totalVotes: string
  status?: StatusOption
  voteForPercentage: string
  voteAgainstPercentage: string
}

export function useGovernanceDetails(index: string) {
  const contact = useGovernanceContract()
  const proposesRes = useSingleCallResult(contact, 'proposes', [index])
  const resultRes = useSingleCallResult(contact, 'getResult', [index])

  const ret: GovernanceData = useMemo(() => {
    const result = proposesRes.result
    const data = {
      id: index,
      title: result ? result.subject : '',
      creator: result ? result.creator : '',
      timeLeft: result ? result.endTime.toString() : '',
      voteFor: result ? result.yes.toString() : '',
      voteAgainst: result ? result.no.toString() : '',
      totalVotes: result ? result.totalStake.toString() : '',
      contents: result ? JSON.parse(result.content) : undefined,
      status: resultRes.result
        ? resultRes.result.toString() === '1'
          ? StatusOption.Success
          : resultRes.result.toString() === '2'
          ? StatusOption.Failed
          : StatusOption.Live
        : StatusOption.Live
    }
    return {
      ...data,
      voteForPercentage: `${calcVoteForPercentage(VoteOption.FOR, data.voteFor, data.voteAgainst)}%`,
      voteAgainstPercentage: `${calcVoteForPercentage(VoteOption.AGAINST, data.voteFor, data.voteAgainst)}%`
    }
  }, [index, resultRes.result, proposesRes])

  const returnVal = useMemo(() => ({ data: ret, loading: proposesRes.loading }), [ret, proposesRes.loading])

  return returnVal
}

export function useGovernanceCount(): number | undefined {
  const contact = useGovernanceContract()
  const res = useSingleCallResult(contact, 'proposeCount')
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return undefined
}

export function useGovernanceList(): { list: GovernanceData[] | undefined; loading: boolean } {
  const contact = useGovernanceContract()
  const proposeCount = useGovernanceCount()
  const proposeIndexes = []
  for (let i = 0; i < (proposeCount ?? 0); i++) {
    proposeIndexes.push([i])
  }
  const proposesListRes = useSingleContractMultipleData(contact, 'proposes', proposeIndexes)
  const proposesStatusRes = useSingleContractMultipleData(contact, 'getResult', proposeIndexes)

  const isLoading = useRef(true)
  const list = useMemo(
    () =>
      proposesListRes
        .map(({ result, loading }, index) => {
          isLoading.current = loading
          const title: string = result?.subject
          const creator: string = result?.creator
          const timeLeft: string = result?.endTime.toString()
          const voteFor: string = result?.yes.toString()
          const voteAgainst: string = result?.no.toString()
          const totalVotes: string = result?.totalStake.toString()
          const summary: string = result ? JSON.parse(result?.content).summary : ''
          const details: string = result ? JSON.parse(result?.content).details : ''
          const agreeFor: string = result ? JSON.parse(result?.content).agreeFor : ''
          const againstFor: string = result ? JSON.parse(result?.content).againstFor : ''
          let status: StatusOption = StatusOption.Live
          if (proposesStatusRes && proposesStatusRes[index] && proposesStatusRes[index].result) {
            const _status = proposesStatusRes[index].result ?? '2'
            status =
              _status.toString() === '1'
                ? StatusOption.Success
                : _status.toString() === '2'
                ? StatusOption.Failed
                : StatusOption.Live
          }

          return {
            id: index.toString(),
            title,
            creator,
            timeLeft,
            voteFor,
            voteAgainst,
            totalVotes,
            voteForPercentage: `${calcVoteForPercentage(VoteOption.FOR, voteFor, voteAgainst)}%`,
            voteAgainstPercentage: `${calcVoteForPercentage(VoteOption.AGAINST, voteFor, voteAgainst)}%`,
            contents: {
              summary,
              details,
              agreeFor,
              againstFor
            },
            status
          }
        })
        .reverse(),
    [proposesListRes, proposesStatusRes]
  )
  return { list, loading: isLoading.current }
}

export function useUserStaking(proposeid: string | number | undefined): Users {
  const { account } = useWeb3React()
  const contact = useGovernanceContract()
  const usersRes = useSingleCallResult(contact, 'users', [proposeid, account ?? undefined])

  const res = usersRes.result

  const ret = useMemo(
    () => ({
      totalNo: res ? res.totalNo.toString() : '',
      totalStake: res ? res.totalStake.toString() : '',
      totalYes: res ? res.totalYes.toString() : '',
      stakeEndTime: res ? parseInt(res.stakeEndTime.toString()) : 0
    }),
    [res]
  )

  return ret
}

export function useGovernanceCreation() {
  const governanceContract = useGovernanceContract()

  const estimate = governanceContract?.estimateGas.propose
  const method: (...args: any) => Promise<TransactionResponse> = governanceContract?.propose
  return useMemo(() => {
    if (!estimate) return undefined
    return (args: any[]) =>
      estimate(...args, {}).then(estimatedGasLimit =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit)
        })
      )
  }, [estimate, method])
}
